import React from 'react';
import CreateIconComponent, {
  CreateIconComponentProps,
} from '@zola/zola-ui/src/components/SvgIcons/CreateIconComponent';

const ChevronIcon = ({
  title = 'X',
  viewBox = '0 0 24 24',
  ...props
}: CreateIconComponentProps): JSX.Element => (
  <CreateIconComponent title={title} viewBox={viewBox} {...props} fill="none">
    {/* prettier-ignore */}
    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </CreateIconComponent>
);

export default ChevronIcon;
