import React, { useCallback, useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';

import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import _isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import NavSecondary from 'components/navV2/NavSecondary';
import NavScrollContainer from 'components/navV2/NavScrollContainer';

import useNavV1Methods from 'components/navV2/useNavV1Methods';
import useNavData from 'components/navV2/useNavData';

import { UniversalTabs } from 'reducers/nav';
import type { WStoreNavView } from '@zola/svc-web-api-ts-client';
import { setActiveUniversalTab } from 'actions/NavActions';
import homeStorePrimaryNav from '../../navV2/isHomeStore';

import '../../navV2/navV2.less';
import type { DesktopV3MapDispatchProps, DesktopV3MapStateProps } from './DesktopWrapper';
import DesktopContainer from './DesktopContainer';
import MultiAnnouncementBanner from '../MultiAnnouncementBanner/MultiAnnouncementBanner';

// event data to be received from initNavData for setting home store tab as default when loading
type DesktopV3Props = DesktopV3MapStateProps & DesktopV3MapDispatchProps;

const DesktopV3: FC<DesktopV3Props> = ({
  addUserContextToRedux,
  maybeFetchUser,
  fetchCategories,
  fetchWeddingShopCategories,
  userContext,
  activeUniversalTab,
}) => {
  const [homeStoreNav, setHomeStoreNav] = useState<WStoreNavView>({ categories: [] });
  const dispatch = useDispatch();
  const userContextExists = userContext && !_isEmpty(userContext);

  // useCallback so the referential check in useNavData's useEffect works as expected
  const onInitNavData = useCallback(
    (passedUserContext, defaultTab) => {
      if (defaultTab === 'HOME_STORE') {
        dispatch(setActiveUniversalTab(UniversalTabs.HOME_STORE));
      }
      if (!_isEmpty(passedUserContext)) addUserContextToRedux(passedUserContext);
    },
    [addUserContextToRedux, dispatch]
  );

  const {
    secondaryNavData,
    primaryLinkId,
    secondaryLinkId,
    disablePrimaryCollapse,
    emitDirectionEvent,
  } = useNavData(onInitNavData, userContext, activeUniversalTab);
  // Extracted hook for V1 support
  useNavV1Methods({
    maybeFetchUser,
    fetchCategories,
    fetchWeddingShopCategories,
  });

  const isHomeStoreActive = activeUniversalTab === UniversalTabs.HOME_STORE;

  const navContainerClasses = cx('navV3__container', {
    'nav-container--blue-background': isHomeStoreActive,
  });

  // the dropdowns for NavPrimary and NavSecondary look different
  // the most efficient and minimal changes for home store nav:
  // hide primary nav
  // add carets to secondary nav
  // just style the css so secondary nav looks like primary nav
  const returnSecondaryNavLinks = (showHomeStoreLinks: boolean) => {
    if (showHomeStoreLinks && homeStoreNav?.categories) {
      return homeStoreNav.categories.length > 0
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          homeStorePrimaryNav(homeStoreNav as any, userContext as UserContext)
        : [];
    }
    // eslint-disable-next-line consistent-return
    return secondaryNavData;
  };

  if (!userContextExists) {
    maybeFetchUser();
  }

  useEffect(() => {
    if (isHomeStoreActive && homeStoreNav?.categories?.length === 0) {
      fetchCategories('STORE')
        .then(setHomeStoreNav)
        .catch(() => undefined);
    }
  }, [fetchCategories, homeStoreNav, isHomeStoreActive, setHomeStoreNav]);

  return (
    <NavScrollContainer
      activeLinkId={primaryLinkId === '' ? undefined : primaryLinkId}
      disablePrimaryCollapse={disablePrimaryCollapse}
      emitDirectionEvent={emitDirectionEvent}
    >
      <nav className={navContainerClasses}>
        <MultiAnnouncementBanner showPreauthLinks leftAlignBanners isPostAuth={false} />
        <DesktopContainer activeLinkId={primaryLinkId} />
        <NavSecondary
          secondaryNavData={returnSecondaryNavLinks(isHomeStoreActive)}
          activeLinkId={secondaryLinkId}
        />
      </nav>
    </NavScrollContainer>
  );
};

export default DesktopV3;
