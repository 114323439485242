import React, { Fragment, useCallback, useEffect, useState, MouseEvent, useRef } from 'react';
import { connect } from 'react-redux';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ZolaLogo } from '@zola/zola-ui/src/components/ZolaLogo';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import SearchIcon from 'components/navV3/iconsV3/Search';
import CaretIcon from '@zola/zola-ui/src/components/SvgIcons/CaretV2';
import ChevronIcon from 'components/navV3/iconsV3/Chevron';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

import ArrowIcon from '@zola/zola-ui/src/components/SvgIcons/Arrow';
import NAV_DATA, {
  PrimaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import _isNumber from 'lodash/isNumber';
import cx from 'classnames';
import CartContainer from 'components/nav/TopNav/CartContainer';

import { toggleDocumentOverlayClass } from 'util/overlayHelper';
import { showModal } from 'actions/ModalActions';
import signupLinkCallback from 'components/common/auth/AuthModal/helpers/signupLinkCallback';
import type { AppDispatch, RootState } from 'reducers/index';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { transformNavigationLevel } from 'util/trackNavigationClickedHelpersV2';
import UniversalSearch from 'components/search/UniversalSearch';
import NavLoginOrSignUp from 'components/navV2/NavLoginOrSignUp';
import featureFlags from 'util/featureFlags';
import { useCurrentVendorSearchedLocation } from 'src/hooks/useCurrentVendorSearchedLocation';

import {
  getPrimaryPlan,
  primaryAdvice,
  PrimaryNavItemType,
  SecondaryNavItemType,
} from '../preauthNavData';
import CloseIcon from '../iconsV3/CloseIcon';

import type { PrimaryNavType } from '../preauthNavData';

import styles from './DesktopContainer.module.less';

import 'components/navV2/NavUtil/navUtil.less';

const {
  EVENTS: { MODAL_TYPE_AUTH },
} = NAV_DATA;

type DesktopSubMenuProps = {
  closeFn: () => void;
  itemIndex: number;
  primaryNav: PrimaryNavType;
};

const DesktopSubMenu: React.FC<DesktopSubMenuProps> = ({ closeFn, itemIndex, primaryNav }) => {
  const item = primaryNav.nav[itemIndex];

  const handleTrackNavigationClicked = (
    subNavItem: SecondaryNavItemType,
    subNavItemIndex: number
  ) => {
    trackNavigationClicked({
      business_category: subNavItem.businessCategory || 'UNATTRIBUTED',
      business_unit: subNavItem.businessUnit || 'UNATTRIBUTED',
      navigation_level_1: transformNavigationLevel(primaryNav.title),
      navigation_level_1_position: primaryNav.position,
      navigation_level_2: transformNavigationLevel(item.label),
      navigation_level_2_position: itemIndex + 1,
      navigation_level_3: transformNavigationLevel(subNavItem.label),
      navigation_level_3_position: subNavItemIndex + 1,
      navigation_type: 'GLOBAL',
    });
  };

  return (
    <div className={styles.secondarySideNavWrapper}>
      <button
        id="navCloseModal"
        className={styles.closeButton}
        onClick={closeFn}
        type="button"
        aria-label="Close"
      >
        <CloseIcon width={24} height={24} showTitle={false} />
        <span className={styles.screenReaderText}>Close</span>
      </button>
      <nav>
        {item.subnav?.map((subNav, subNavIndex) => (
          <div key={subNavIndex} className={styles.sideNavSubSection}>
            {subNav.title && (
              <div className={styles.sideNavSubMenuSectionLabel}>{subNav.title}</div>
            )}
            <ul>
              {subNav.items.map((subNavItem, subNavItemIndex) => (
                <li key={subNavItemIndex} className={styles.sideNavSubMenuItem}>
                  <a
                    className={styles.sideNavSubMenuItemLink}
                    href={subNavItem.path}
                    onClick={() => handleTrackNavigationClicked(subNavItem, subNavItemIndex)}
                  >
                    {subNavItem.label}
                    {subNavItem.arrow && (
                      <ArrowIcon
                        flip="horizontal"
                        showTitle={false}
                        width={1.5}
                        height={-1}
                        unit="em"
                      />
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </div>
  );
};

type DesktopMenuProps = {
  primaryNav: PrimaryNavType;
  visibility: string;
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
  closeFn: () => void;
};

const DesktopMenu: React.FC<DesktopMenuProps> = ({
  primaryNav,
  visibility,
  activeIndex,
  setActiveIndex,
  closeFn,
}) => {
  useEffect(() => {
    setActiveIndex(null);
  }, [primaryNav.title, setActiveIndex]);

  const [fix, setFix] = useState(visibility);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [clientX, setClientX] = useState(0);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (visibility === 'hidden') {
      timeoutRef.current = window.setTimeout(() => setFix('hidden'), 690);
    } else {
      setFix('visible');
    }
    return () => {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, [visibility]);

  const handleTrackNavigationClicked = (item: PrimaryNavItemType, index: number) => {
    trackNavigationClicked({
      business_category: item.businessCategory || 'UNATTRIBUTED',
      business_unit: item.businessUnit || 'UNATTRIBUTED',
      navigation_level_1: transformNavigationLevel(primaryNav.title),
      navigation_level_1_position: primaryNav.position,
      navigation_level_2: transformNavigationLevel(item.label),
      navigation_level_2_position: index + 1,
      navigation_type: 'GLOBAL',
    });
  };

  const setActiveIndexDelayed = (e: MouseEvent, newIndex: number) => {
    const changeX = Math.abs(clientX - e.clientX);
    if (changeX > 10 && _isNumber(activeIndex)) {
      const timeoutIdInstance = setTimeout(() => setActiveIndex(newIndex), 300);
      setTimeoutId(timeoutIdInstance);
    } else {
      setActiveIndex(newIndex);
    }
    setClientX(e.clientX);
  };

  const cancelSetActiveIndexDelayed = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  return (
    <Fragment>
      <div className={cx(styles.sideNavContainer, visibility !== 'hidden' ? styles.open : '')}>
        {fix !== 'hidden' && (
          <nav className={cx(styles.subNav, activeIndex !== null ? styles.hasActiveSubNav : '')}>
            <ul>
              {primaryNav.nav.map((item, index) => (
                <li
                  key={index}
                  className={cx(
                    styles.sideNavMenuItem,
                    activeIndex === index ? styles.sideNavMenuItemActive : ''
                  )}
                  onMouseEnter={(e) => setActiveIndexDelayed(e, index)}
                  onMouseLeave={cancelSetActiveIndexDelayed}
                >
                  <a
                    className={styles.sideNavMenuItemLink}
                    href={item.path}
                    onClick={() => handleTrackNavigationClicked(item, index)}
                  >
                    <span>{item.label}</span>
                    {item.subnav && <CaretIcon direction="right" showTitle={false} />}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
      <div
        className={cx(
          styles.secondarySideNav,
          visibility !== 'hidden' ? styles.open : '',
          _isNumber(activeIndex) &&
            primaryNav.nav[activeIndex] &&
            primaryNav.nav[activeIndex].subnav
            ? styles.openSecondary
            : ''
        )}
      >
        {_isNumber(activeIndex) && (
          <DesktopSubMenu closeFn={closeFn} itemIndex={activeIndex} primaryNav={primaryNav} />
        )}
      </div>
    </Fragment>
  );
};

type SideNavContainerProps = {
  visibility: string;
  section: string;
  closeFn: () => void;
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
};

const SideNavContainer: React.FC<SideNavContainerProps> = ({
  section,
  visibility,
  closeFn,
  activeIndex,
  setActiveIndex,
}): JSX.Element => {
  const { searchedLocation } = useCurrentVendorSearchedLocation();
  const [seatingChartV2Released, setSeatingChartV2Released] = useState<boolean>(false);

  const isPlanSection = section === 'plan';
  let isDisableSecondaryFlyoutEnabled: boolean | undefined;

  // Only trigger experiment if the "Plan your wedding" is hovered over
  if (isPlanSection && visibility !== 'hidden') {
    isDisableSecondaryFlyoutEnabled = true;
  }

  useEffectOnce(() => {
    setSeatingChartV2Released(featureFlags.get('seatingChartV2Released'));
  });

  return (
    <div className={styles.sideNavPortal}>
      {visibility !== 'hidden' && (
        <div
          className={styles.sideNavFade}
          onClick={closeFn}
          onKeyDown={closeFn}
          onMouseEnter={closeFn}
        />
      )}
      <DesktopMenu
        primaryNav={
          isPlanSection
            ? getPrimaryPlan({
                location: searchedLocation,
                seatingChartV2Released,
                shouldDisableSubnav: isDisableSecondaryFlyoutEnabled,
              })
            : primaryAdvice
        }
        visibility={visibility}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        closeFn={closeFn}
      />
    </div>
  );
};

type DesktopContainerProps = {
  onShowLogin: () => void;
  activeLinkId?: '' | PrimaryNavIdentifier;
};

const DesktopContainer: React.FC<DesktopContainerProps> = ({
  activeLinkId,
  onShowLogin,
}): JSX.Element => {
  const [flyoutVisibility, setFlyoutVisibility] = useState('hidden');
  const [flyoutSection, setFlyoutSection] = useState('plan');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activePrimaryNavItem, setActivePrimaryNavItem] = useState<string | null>(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const findACouple = 'Find a couple';
  const logIn = 'Log in';

  const openFlyout = (navSection: string) => {
    setFlyoutSection(navSection);
    setFlyoutVisibility('visible');
    toggleDocumentOverlayClass(true, 'test-jason');
    setActiveIndex(null);
    setActivePrimaryNavItem(navSection);
  };

  const openFlyoutHover = (navSection: string) => {
    if (flyoutVisibility === 'hidden') {
      const timeoutIdInstance = setTimeout(() => openFlyout(navSection), 300);
      setTimeoutId(timeoutIdInstance);
    } else {
      openFlyout(navSection);
    }
  };

  const cancelFlyoutHover = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  const closeFlyout = useCallback(() => {
    setFlyoutVisibility('hidden');
    toggleDocumentOverlayClass(false, 'test-jason');
    setActiveIndex(null);
    setActivePrimaryNavItem(null);
  }, []);

  const escFunction = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeFlyout();
      }
    },
    [closeFlyout]
  );

  useEffectOnce(() => {
    document.addEventListener('keydown', escFunction, false);
    window.dispatchEvent(new CustomEvent(NAV_DATA.EVENTS.NAV_PRIMARY_RENDERED));

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  });

  const toggleSearchActive = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleTrackNavigationClicked = (title: string, position: number) => {
    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: transformNavigationLevel(title),
      navigation_level_1_position: position,
      navigation_type: 'GLOBAL',
    });
  };

  return (
    <div className={cx(styles.desktopNavContainer)}>
      <div className={styles.desktopNav}>
        <nav
          className={cx(styles.topNavSection, styles.left)}
          onMouseEnter={() => activePrimaryNavItem === 'community' && setActiveIndex(null)}
        >
          <LinkV2
            type="button"
            role="button"
            sizes="smaller"
            noUnderline
            onClick={() => openFlyout('plan')}
            onMouseEnter={() => openFlyoutHover('plan')}
            onMouseLeave={cancelFlyoutHover}
            className={cx(
              styles.menuTrigger,
              activePrimaryNavItem && activePrimaryNavItem !== 'plan'
                ? styles.inactivePrimaryNavItem
                : ''
            )}
          >
            Plan your wedding
            <ChevronIcon direction="down" height={20} width={20} showTitle={false} />
          </LinkV2>
          <LinkV2
            type="button"
            role="button"
            sizes="smaller"
            noUnderline
            onClick={() => openFlyout('community')}
            onMouseEnter={() => openFlyoutHover('community')}
            onMouseLeave={cancelFlyoutHover}
            className={cx(
              styles.menuTrigger,
              activePrimaryNavItem && activePrimaryNavItem !== 'community'
                ? styles.inactivePrimaryNavItem
                : ''
            )}
          >
            Expert advice
            <ChevronIcon direction="down" height={20} width={20} showTitle={false} />
          </LinkV2>
          <LinkV2
            sizes="smaller"
            noUnderline
            style={{ textTransform: 'none' }}
            href="/search/wedding-registry"
            onClick={() => handleTrackNavigationClicked(findACouple, 3)}
          >
            {findACouple}
          </LinkV2>
        </nav>
        <div className={cx(styles.topNavSection, styles.middle)}>
          <a className={styles.logoLink} href="/" data-name="Zola Logo">
            <ZolaLogo className={styles.logo} color={COLORS.BLACK_100} />
          </a>
        </div>
        <div className={cx(styles.topNavSection, styles.right)}>
          <button
            type="button"
            className="icon-link-v3"
            style={{ marginRight: 0 }}
            onClick={toggleSearchActive}
          >
            <SearchIcon className="nav-util__icon-v3" height={44} width={44} />
          </button>

          <div className={isSearchActive ? styles.searchActive : styles.searchInactive}>
            {isSearchActive && (
              <UniversalSearch activeLinkId={activeLinkId} onCloseSearch={toggleSearchActive} />
            )}
          </div>
          <CartContainer isDesktopNavV3 />
          <ButtonV3
            data-testid="nav-login-button"
            variant="secondary"
            onClick={() => {
              handleTrackNavigationClicked(logIn, 1);
              onShowLogin();
            }}
          >
            {logIn}
          </ButtonV3>
          <NavLoginOrSignUp />
        </div>
      </div>
      <SideNavContainer
        visibility={flyoutVisibility}
        section={flyoutSection}
        closeFn={closeFlyout}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userContext: state.user.userContext,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onShowLogin: () => {
    const path = window.location.pathname;
    const isInvitesDirectSignUpEnabled =
      path.indexOf('holiday-cards') !== -1 || path.indexOf('wedding-albums') !== -1;
    dispatch(
      showModal(
        MODAL_TYPE_AUTH,
        {
          authState: 'login',
          signupLinkCallback:
            isInvitesDirectSignUpEnabled &&
            window.zolaPaper &&
            window.zolaPaper.openSignUpModal instanceof Function
              ? () => window.zolaPaper.openSignUpModal()
              : () => signupLinkCallback(),
        },
        { size: 'sm', ariaLabel: 'Log In', v2Styles: true }
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopContainer);
