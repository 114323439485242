import { connect } from 'react-redux';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { getCategories, getWeddingShopCategories } from 'actions/NavActions';
import { getUserContext } from 'selectors/user';
import { receiveUser, maybeFetchUser } from 'actions/UserActions';
import type { WStoreNavView } from '@zola/svc-web-api-ts-client';

import DesktopV3 from './DesktopV3';
import type { RootState, AppDispatch } from '../../../reducers';

export type DesktopV3MapStateProps = {
  userContext?: UserContext;
  activeUniversalTab: string;
};

const mapStateToProps = (state: RootState): DesktopV3MapStateProps => ({
  userContext: getUserContext(state),
  activeUniversalTab: state.nav.activeUniversalTab,
});

export type DesktopV3MapDispatchProps = {
  addUserContextToRedux: (userContext: UserContext) => void;
  maybeFetchUser: (shouldForceUserFetch?: boolean) => void;
  fetchCategories: (businessUnit: string) => Promise<WStoreNavView>;
  fetchWeddingShopCategories: () => void;
};

const mapDispatchToProps = (dispatch: AppDispatch): DesktopV3MapDispatchProps => ({
  addUserContextToRedux: (userContext) => {
    dispatch(receiveUser(userContext));
  },
  maybeFetchUser: (shouldForceUserFetch) => dispatch(maybeFetchUser(shouldForceUserFetch)),
  fetchCategories: (businessUnit) => dispatch(getCategories(businessUnit)),
  fetchWeddingShopCategories: () => dispatch(getWeddingShopCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopV3);
